import React, { useContext } from 'react';
import { RichText } from 'prismic-reactjs';
import styles from './CardBanner.module.scss';
import { object, array } from 'prop-types';
import { langPath } from '@helpers';
import LangContext from '@contexts';
import { Link } from 'gatsby';
import Image from '../../../../components/Image/Image';

const CardBanner = ({ primary, items }) => {
  const { title } = primary;
  const currentLang = useContext(LangContext);
  return (
    <section className={styles.CardBanner}>
      <div className={styles.cardContainer}>
        <div className={styles.title}>
          <RichText render={title.richText} />
        </div>
        <div className={styles.bannerData}>
          {items?.map(({ title, description, screenshot, pagelink }, index) => {
            const link = `${langPath(currentLang)}/${RichText.asText(
              pagelink.richText
            )}`;
            return (
              <Link to={link} key={index}>
                <div className={styles.bannerContent} key={index}>
                  <div className={styles.bannerImg}>
                    <Image image={screenshot} />
                  </div>
                  <div className={styles.bannerText}>
                    <RichText render={title?.richText} />
                  </div>
                  <div className={styles.bannerDesc}>
                    <RichText render={description?.richText} />
                  </div>
                </div>
              </Link>
            );
          })}
        </div>
      </div>
    </section>
  );
};

CardBanner.propTypes = {
  primary: object,
  items: array,
};

export default CardBanner;
