import React, { useEffect, useState } from 'react';
import { array, object } from 'prop-types';
import styles from './RelatedBlog.module.scss';
import { RichText } from 'prismic-reactjs';
import Articles from '../Articles/Articles';

const RelatedBlog = ({ primary, ArticleList, tagss }) => {
  const [isData, setIsData] = useState([]);

  const { title } = primary;
  // let tag = [];
  // items.map(({ tag: { richText } }) => {
  //   tag.push(richText[0]?.text.toLowerCase());
  // });
  // console.log(tagss, 'tagss');

  // tag = items[0]?.tag?.richText[0]?.text.toLowerCase();

  const filteredArticles = ArticleList?.filter(({ node }) => {
    const searchData = JSON.stringify({
      tags: node?.tags,
    }).toLowerCase();
    // console.log(searchData, 'searchData');

    for (let i = 0; i < tagss.length; i++) {
      if (searchData.includes(tagss[i].toLowerCase())) {
        return searchData.includes(tagss[i].toLowerCase());
      }
    }
    // return searchData.includes(tag);
  });

  // console.log(filteredArticles, 'filteredArticles');

  useEffect(() => {
    if (filteredArticles.length === 0) {
      setIsData(ArticleList);
    } else {
      setIsData(filteredArticles);
    }
  }, []);

  return (
    <section className={styles.relatedBlog}>
      <div className={styles.container}>
        <div className={styles.title}>
          <RichText render={title.richText} />
        </div>
        <div className={styles.blogPreview}>
          <Articles filteredArticles={isData} />
        </div>
      </div>
    </section>
  );
};

RelatedBlog.propTypes = {
  node: object,
  primary: object,
  ArticleList: array,
  tagss: array,
};

export default RelatedBlog;
