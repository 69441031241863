import React, { useContext, useState } from 'react';
import { array } from 'prop-types';
import style from './Articles.module.scss';
import Button, { VARIANT } from '@components/Button/Button.js';
import ArticlePreview from '@components/ArticlePreview';
// import { parseString } from '@helpers';
import LangContext from '@contexts';
import { langPath } from '@helpers';
const numberToRender = 3; // started article on the page
const COUNTER_STEP = 3;
import { Link } from 'gatsby';

const Articles = ({ filteredArticles }) => {
  const [counter, setCounter] = useState(numberToRender);
  const currentLang = useContext(LangContext);

  const handleClickLoadMore = () => {
    setCounter(counter + COUNTER_STEP);
  };

  return (
    <section className={style.articles}>
      <div className={style.list}>
        {filteredArticles?.slice(0, counter)?.map((item) => {
          return <ArticlePreview {...item} key={item.node.uid} />;
        })}
      </div>

      <div className={style.buttonWrapper}>
        {filteredArticles?.length >= 3 && counter < filteredArticles.length && (
          <Button
            variant={VARIANT.SECONDARY}
            click={handleClickLoadMore}
            element="button"
            fullWidth
          >
            See More Articles
          </Button>
        )}
      </div>
      <div className={style.wrappernoList}>
        {!filteredArticles?.length && (
          <>
            {/* <h5> {`No blogposts were found with this tag 😥`} </h5> */}
            <h5> {`No blogposts were found`} </h5>
            <div className={style.buttonWrapper}>
              <Link to={`${langPath(currentLang)}/blog`}>
                <Button
                  variant={VARIANT.TRANSPARENT}
                  element="button"
                  fullWidth
                >
                  Back to Blog
                </Button>
              </Link>
            </div>
          </>
        )}
      </div>
    </section>
  );
};

Articles.propTypes = {
  filteredArticles: array,
};

export default Articles;
